import './areas.scss';

const Areas = ({ title, areas }) => (
  <section className="areas">
    <div className="container">
      <h2 className="section-title">{title}</h2>

      <ul>
        {areas?.map((el, index) => (
          <li key={index}>{el.location}</li>
        ))}
      </ul>
    </div>
  </section>
);

export default Areas;
