import { Button } from '../../components';

import './callUs.scss';

const CallUs = ({ phone }) => (
  <section className="call-us">
    <div className="container">
      <div className="call-us__content">
        <h2 className="section-title">
          Get a free moving
          <br />
          estimate today!
        </h2>

        <div className="links">
          <Button to="/book-now" text="Book Now" />

          <span>
            or call us <a href={`tel:${{ phone }}`}>{phone}</a>
          </span>
        </div>
      </div>

      <div className="call-us__media" />
    </div>
  </section>
);

export default CallUs;
