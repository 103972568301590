import { Layout } from '../components';

import { PageBackground, ContactsGrid } from '../sections';

const Contacts = () => {
  return (
    <Layout id="contact">
      <PageBackground title="Contact Us" />
      <ContactsGrid />
    </Layout>
  );
};

export default Contacts;
