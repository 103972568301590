import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Button } from '../../components';

import './postsGrid.scss';

const PostsGrid = ({ data, categories }) => {
  const [postToShow, setPostsToShow] = useState(data);
  const [category, setCategory] = useState('All');

  const handleChangeCategory = (name) => setCategory(name);

  useEffect(() => {
    const currentCategory = categories.find((el) => el.name === category)?.id;

    if (currentCategory !== undefined) setPostsToShow(data?.filter((el) => el.categories.includes(currentCategory)));
    else setPostsToShow(data);
  }, [category]);

  return (
    <section className="posts-grid">
      <div className="container">
        <div className="posts-grid__categories">
          <div
            className={`posts-grid__categories--item ${category === 'All' ? 'active' : null}`}
            onClick={() => handleChangeCategory('All')}
          >
            All
          </div>

          {categories.map((el) => (
            <div
              className={`posts-grid__categories--item ${category === el.name ? 'active' : null}`}
              key={el.id}
              onClick={() => handleChangeCategory(el.name, el.id)}
            >
              {el.name}
            </div>
          ))}
        </div>

        <div className="posts-grid__list">
          {postToShow?.map((post) => (
            <Link to={post.slug} className="posts-grid__card" key={post.id}>
              <div className="posts-grid__card--image">
                <img src={post.acf.image} alt={post.title.rendered} />
              </div>
              <div className="posts-grid__card--content">
                <div className="posts-grid__card--meta">{moment(post.date_gmt).format('MMMM DD, YYYY')}</div>
                <h3>{post.title.rendered}</h3>
                <Button to={post.slug} text="Read More" contained={false} />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PostsGrid;
