import useFetch from 'react-fetch-hook';

import { Layout, Seo } from '../components';

import { PageBackground, PostsGrid } from '../sections';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

const POSTS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/posts?per_page=100';

const CATEGORIES_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/categories?per_page=100';

const Blog = () => {
  const settings = useFetch(SETTINGS_URL);
  const categories = useFetch(CATEGORIES_URL);
  const { data, error, isLoading } = useFetch(POSTS_URL);

  if (settings.isLoading || categories.isLoading || isLoading) return;

  return (
    <Layout id="blog">
      <Seo data={data[0]?.yoast_head_json} />
      <PageBackground title="Blog" />

      <PostsGrid data={data} categories={categories.data} />
    </Layout>
  );
};

export default Blog;
