import Slider from 'react-slick';

import './reviews.scss';

const sliderSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1025,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 769,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Reviews = ({ data }) => (
  <section className="reviews">
    <div className="container">
      <h2 className="section-title">What Our Clients Have to Say</h2>

      <Slider className="reviews__list" {...sliderSettings}>
        {data?.map((item, index) => (
          <ReviewCard {...{ review: item, key: index }} />
        ))}
      </Slider>
    </div>
  </section>
);

export default Reviews;

const ReviewCard = ({ review }) => {
  let icon;

  switch (review.source) {
    case 'Facebook':
      icon = '/images/f.svg';
      break;
    case 'Yelp':
      icon = '/images/y.svg';
      break;
    default:
      icon = '/images/g.svg';
      break;
  }

  return (
    <div className="reviews__list--card">
      <div className="reviews__list--card__header">
        <div className="reviews__list--card__logo">
          <img src={icon} alt={review.logo} />
        </div>
        <div className="reviews__list--card__stars">
          <img src="../images/red-star-icon.svg" alt="red star" />
          <img src="../images/red-star-icon.svg" alt="red star" />
          <img src="../images/red-star-icon.svg" alt="red star" />
          <img src="../images/red-star-icon.svg" alt="red star" />
          <img src="../images/red-star-icon.svg" alt="red star" />
        </div>
      </div>
      <h5>{review.name}</h5>
      <span>{review.date}</span>
      <p>{review.text}</p>
    </div>
  );
};
