import { Button } from '../../components';

import './services.scss';

const Services = ({ title, list }) => (
  <section className="services">
    <div className="container">
      <h2 className="section-title">{title}</h2>

      <div className="services__list">
        {list.map((el, index) => (
          <div className="services__list--card" key={index}>
            <header>
              <div>
                <img src={el.icon} alt={el.title} />
              </div>

              <h4>
                {el.title.split(' ')[0]}
                <br />
                {el.title.split(' ')[1]}
              </h4>
            </header>

            <p>{el.description}</p>

            <div className="services__list--links">
              <Button href={`/services/${el.link.split('/')[3]}`} text="Learn More" />

              <Button href={`/book-now`} text="Book Now" />
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Services;
