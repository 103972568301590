import './whyWe.scss';

const WhyWe = ({ title, content, labels }) => (
  <section className="why-we">
    <div className="container">
      <h2 className="section-title">{title}</h2>

      <div className="why-we__content">
        <div dangerouslySetInnerHTML={{ __html: content }} />

        <div className="why-we__content--labels">
          {labels?.map((el, index) => (
            <div key={index} className="why-we__content--card">
              <img src={el.icon} alt={el.title} />

              <span>{el.title}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>
);

export default WhyWe;
