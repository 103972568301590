import { Routes, Route, Navigate } from 'react-router-dom';

import { Home, LongDistanceMovers, LocalMovers, Blog, Post, Contacts, BookNow, LegalPage } from './pages';

export const ADMIN_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2';

const App = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate replace to="/404" />} />
      <Route path="/home" element={<Navigate replace to="/" />} />

      <Route path="/" element={<Home />} />
      <Route path="/services/long-distance-movers" element={<LongDistanceMovers />} />
      <Route path="/services/local-movers" element={<LocalMovers />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/:slug" element={<Post />} />
      <Route path="/contact" element={<Contacts />} />
      <Route path="/book-now" element={<BookNow />} />

      <Route path="/privacy-policy" element={<LegalPage />} />
      <Route path="/terms-of-use" element={<LegalPage />} />
      <Route path="/accessibility-statement" element={<LegalPage />} />
    </Routes>
  );
};

export default App;
