import { useParams } from 'react-router-dom';
import useFetch from 'react-fetch-hook';

import { Layout, Seo } from '../components';

import { PostContent } from '../sections';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

const POST_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/posts';

const Post = () => {
  const settings = useFetch(SETTINGS_URL);
  const { slug } = useParams();

  const { data, error, isLoading } = useFetch(`${POST_URL}?slug=${slug}`);

  if (settings.isLoading || isLoading) return;

  return (
    <Layout id="post">
      <Seo data={data[0]?.yoast_head_json} />
      <PostContent post={data} />
    </Layout>
  );
};

export default Post;
