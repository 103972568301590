import { Form } from '../../components';

import './contacts.scss';

const ContactsGrid = () => (
  <section className="contacts">
    <div className="container">
      <div className="contacts__info">
        <div>
          <img src="../images/location.svg" alt="Location" />

          <p>
            233 E Harris Ave #28B
            <br />
            San Francisco, CA, 94080
          </p>
        </div>

        <div>
          <img src="../images/phone.svg" alt="Phone" />

          <p>(415) 991-0355</p>
        </div>

        <div>
          <img src="../images/mail.svg" alt="Mail" />

          <p>support@spacemovingsf.com</p>
        </div>
      </div>

      <Form />
    </div>
    <div className="contacts__map">
      <iframe
        title="map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d50470.076122030536!2d-122.47879944399584!3d37.75769282912962!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA!5e0!3m2!1sen!2sus!4v1684191735300!5m2!1sen!2sus"
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  </section>
);

export default ContactsGrid;
