import useFetch from 'react-fetch-hook';
import { Link } from 'react-router-dom';
import moment from 'moment';

import { Form } from '../../components';

import './post.scss';

const POSTS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/posts?per_page=100';

const PostContent = ({ post }) => {
  const { data, isLoading } = useFetch(POSTS_URL);

  if (isLoading) return;

  return (
    <section className="post-content">
      <div className="container">
        <h1>{post[0]?.title?.rendered}</h1>

        <div className="post-content__grid">
          <div className="post-content__grid--text">
            <img src={post[0]?.acf.image} alt={post[0]?.title?.rendered} />

            <span className="date">{moment(post[0]?.date).format('MMMM DD, YYYY')}</span>

            <div dangerouslySetInnerHTML={{ __html: post[0]?.content.rendered }} />
          </div>

          <div className="post-content__grid--sidebar">
            <Form />
            <div className="recent-posts">
              <h4>Recent Posts</h4>

              <ul>
                {data
                  .filter((el) => el.id === post[0].id)
                  .map((el) => (
                    <li key={el.id}>
                      <Link to={`../blog/${el.slug}`}>{el.title.rendered}</Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PostContent;
