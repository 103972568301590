import axios from 'axios';
import { toast } from 'react-toastify';

import './form.scss';

const Form = () => {
  const submit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    try {
      const response = await axios.post(
        'https://admin.spacemovingsf.com/wp-json/contact-form-7/v1/contact-forms/8/feedback',
        data,
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        event.target.reset();
      }
    } catch (error) {
      toast.error('Ooops! Something went wrong! Please, try again!');
      console.log(error);
    }
  };
  return (
    <form onSubmit={submit}>
      <h4>Keep in touch with us</h4>

      <input type="text" name="user_name" placeholder="Full Name *" required />
      <input type="text" name="phone" placeholder="Phone Number *" required />
      <input type="email" name="user_email" placeholder="Email *" required />
      <textarea name="message" placeholder="Tell Us About Your Move" />

      <input type="submit" value="Send" />

      <div class="data-protection-label">
        <svg aria-hidden="true" viewBox="0 0 24 24">
          <path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"></path>
        </svg>
        <p>Data protected and privacy</p>
      </div>
    </form>
  );
};

export default Form;
