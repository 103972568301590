import { useState, useEffect } from 'react';

import './legalContent.scss';

const LegalContent = ({ data }) => {
  const page = window.location.pathname.split('/')[1];
  const [content, setContent] = useState();

  useEffect(() => {
    if (page) {
      switch (page) {
        case 'terms-of-use':
          setContent(data.terms_of_use);
          break;
        case 'accessibility-statement':
          setContent(data.accessibility_statement);
          break;
        default:
          setContent(data.privacy_policy);
          break;
      }
    }
  }, [page]);

  return (
    <section className="legal-content">
      <div className="container" dangerouslySetInnerHTML={{ __html: content }} />
    </section>
  );
};

export default LegalContent;
