import './form.scss';

const ApiForm = () => (
  <div className="api-form__wrapper">
    <iframe
      src="https://portal.space-moving.com/embedded/?companyId=5f9a6947-e133-4a83-84d8-ab6e0163360c&branchId=37c36452-b733-41f1-903a-af10016a01ba&mode=short"
      height="650"
      style={{
        width: '100%',
        height: 725,
        margin: 0,
        borderWidth: 0,
      }}
    />
  </div>
);

export default ApiForm;
