import './pageBackground.scss';

const PageBackground = ({ title }) => (
  <section className="page-bg">
    <div className="container">
      <h2 className="section-title">{title}</h2>
    </div>
  </section>
);

export default PageBackground;
