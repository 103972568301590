import { NavLink } from 'react-router-dom';

import './footer.scss';

const Footer = ({ logo, contacts }) => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="page-footer">
      <div className="container">
        <div className="page-footer__content">
          <NavLink to="/" className="logo">
            <img src={logo} alt="Company Logo" />
          </NavLink>

          <p>
            Experience the ultimate moving solution with Space Moving! Our top-notch services ensure a hassle-free
            relocation. Discover the difference today.
          </p>
        </div>

        <div className="page-footer__contacts">
          <a href={`tel:${contacts.phone}`} className="phone">
            {contacts.phone}
          </a>
          <a href={`mailto:${contacts.email}`} className="email">
            {contacts.email}
          </a>
          {contacts.address.map((el, index) => (
            <div key={index} className="location">
              {el.address}
            </div>
          ))}
        </div>

        <div className="underline">
          <div className="legal-links">
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-of-use">Terms Of Use</NavLink>
            <NavLink to="/accessibility-statement">Accessibility Statement</NavLink>
          </div>

          <p>©{currentYear} Space Moving & Storage. All Rights Reserved.</p>

          <a href="http://toporin.com/" target="_blank" rel="noReferrer">
            Designed by <strong>Toporin Studio®</strong> in California
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
