import useFetch from 'react-fetch-hook';

import { Layout, Seo } from '../components';

import {
  Hero,
  WhyWeSmall,
  CallToAction,
  About,
  Counter,
  BookMove,
  Reviews,
  ContactUs,
  Areas,
  MovingServices,
  Pricing,
} from '../sections';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

const ADMIN_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2';

const hero_title = `Rated #1<br/>Local Movers`;

const LocalMovers = () => {
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=local-movers`);
  const settings = useFetch(SETTINGS_URL);

  if (isLoading || settings.isLoading) return;

  return (
    <Layout id="local-movers">
      <Seo data={data[0]?.yoast_head_json} />
      <Hero title={hero_title} />
      <WhyWeSmall />
      <CallToAction phone={settings.data[0].acf.contacts.phone} />
      <About content={data[0]?.acf.about.content} />
      <MovingServices />
      <Pricing data={data[0]?.acf.pricing} />
      <BookMove phone={settings.data[0].acf.contacts.phone} />
      <Counter />
      <Areas title={settings.data[0].acf.areas_title} areas={settings.data[0].acf.areas} />
      <Reviews data={settings.data[0].acf.reviews} />
      <ContactUs phone={settings.data[0].acf.contacts.phone} />
    </Layout>
  );
};

export default LocalMovers;
