import { Layout, ApiForm } from '../components';

const BookNow = () => {
  return (
    <Layout id="book-now">
      <section>
        <ApiForm />
      </section>
    </Layout>
  );
};

export default BookNow;
