import useFetch from 'react-fetch-hook';

import { Layout, Seo } from '../components';

import { ContactUs, LegalContent } from '../sections';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

// const ADMIN_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2';

const LegalPage = () => {
  const settings = useFetch(SETTINGS_URL);

  if (settings.isLoading) return;

  return (
    <Layout>
      {/* <Seo data={data[0]?.yoast_head_json} /> */}
      <LegalContent data={settings.data[0].acf.legal_info} />
      <ContactUs phone={settings.data[0].acf.contacts.phone} />
    </Layout>
  );
};

export default LegalPage;
