import './counter.scss';

const Counter = () => (
  <section className="counter">
    <div className="container">
      <div className="counter__grid">
        <div>
          <img src="../images/clients.svg" alt="Clients" />
          <span>8042</span>
          <p>Clients</p>
        </div>

        <div>
          <img src="../images/miles.svg" alt="Miles" />
          <span>10932</span>
          <p>Miles For Year</p>
        </div>

        <div>
          <img src="../images/tons.svg" alt="Tons" />
          <span>5721</span>
          <p>Tons Of Good</p>
        </div>
      </div>
    </div>
  </section>
);

export default Counter;
