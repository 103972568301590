import './satisfaction.scss';

const Satisfaction = () => (
  <section className="satisfaction">
    <div className="container">
      <h2 className="section-title">100% Satisfaction Guaranteed</h2>

      <div className="satisfaction__grid">
        <div>
          <img src="./images/google.svg" alt="google" />

          <div>
            <img src="./images/star-icon.svg" alt="star" />
            <span>5.0</span>
          </div>
        </div>

        <div>
          <img src="./images/fb.svg" alt="fb" />

          <div>
            <img src="./images/star-icon.svg" alt="star" />
            <span>5.0</span>
          </div>
        </div>

        <div>
          <img src="./images/yelp.svg" alt="yelp" />

          <div>
            <img src="./images/star-icon.svg" alt="star" />
            <span>5.0</span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Satisfaction;
