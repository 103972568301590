import { Button } from '../../components';

import './cta.scss';

const CallToAction = ({ phone }) => (
  <section className="cta">
    <div className="container">
      <h2 className="section-title">
        Get a Free Moving
        <br />
        Estimate Today!
      </h2>

      <Button href={`tel${phone}`} text={phone} />
    </div>
  </section>
);

export default CallToAction;
