import './pricing.scss';

const Pricing = ({ data }) => (
  <section className="pricing">
    <div className="container">
      <h2 className="section-title">Special Pricing</h2>

      <div className="pricing__grid">
        {data.map((el, index) => (
          <div key={index}>
            <h5>{el.title}</h5>
            <span>from</span>
            <p className="rate">
              ${el.rate}
              <span>/HR</span>
            </p>

            <p className="description">{el.description}</p>

            <ul>
              {el.services.map((item, index) => (
                <li key={index}>
                  <span>{item.service}</span>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  </section>
);

export default Pricing;
