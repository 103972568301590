import './map.scss';

const Map = () => (
  <section className="map">
    <div className="container">
      <h2 className="section-title">Long Distance #10</h2>

      <img src="../images/map.svg" alt="map" />
    </div>
  </section>
);

export default Map;
