import { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Link } from 'react-router-dom';
import useFetch from 'react-fetch-hook';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from '../';

import './layout.scss';

const HEADER_NAVIGATION_URL = 'https://admin.spacemovingsf.com/wp-json/menus/v1/menus/main-navigation';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

const Layout = ({ id, children }) => {
  const [isVisible, setVisibility] = useState(false);

  const header_navigation = useFetch(HEADER_NAVIGATION_URL);
  const settings = useFetch(SETTINGS_URL);

  const handleScroll = () => {
    setVisibility(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [id]);

  if (settings.isLoading || header_navigation.isLoading) return;

  return (
    <div>
      <Header
        menu={header_navigation.data}
        phone={settings.data[0].acf.contacts.phone}
        logo={settings.data[0].acf.logo.header}
      />
      <main id={id}>{children}</main>
      <Footer contacts={settings.data[0].acf.contacts} logo={settings.data[0].acf.logo.footer} />
      <ToastContainer />
      <div className={`mobile-cta-buttons ${isVisible ? 'on' : 'off'}`}>
        <a href={`tel:${settings.data[0].acf.contacts.phone}`}>{settings.data[0].acf.contacts.phone}</a>
        <Link to="/book-now">Book Now</Link>
      </div>
    </div>
  );
};

export default Layout;
