import { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

import { Button } from '../';

import './header.scss';

const Header = ({ logo, menu, phone }) => {
  const [shadow, setShadow] = useState(false);
  const [open, setOpen] = useState(false);
  const location = window.location.href.split('/');

  const menuToggle = () => setOpen(!open);

  const handleScroll = () => setShadow(window.scrollY > 0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <header className={`page-header ${shadow && 'shadow'}`}>
      <div className="top-line">
        <div className="container">
          <span>San Francisco Bay Area</span>
        </div>
      </div>

      <div className="navigation">
        <div className="container">
          <Link to="/" className="logo">
            <img src={logo} alt="Company Logo" />
          </Link>

          <Link className="mobile-phone" to={`tel:${phone}`}>
            {phone}
          </Link>

          <Burger onClick={menuToggle} />

          <nav className={open ? 'open' : null}>
            <CloseButton onClick={menuToggle} />

            <ul className="nav-menu">
              {menu?.items?.map((item, index) => (
                <li key={index}>
                  {item.child_items ? (
                    <div className="nav-submenu">
                      <span className={location[3] === 'services' ? 'active' : null}>{item.title}</span>
                      <ul>
                        {item.child_items.map((subitem, index) => (
                          <li key={index}>
                            <NavLink to={`/${item.title.toLowerCase()}/${subitem.slug}`}>{subitem.title}</NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <NavLink to={`/${item.slug}`}>{item.title}</NavLink>
                  )}
                </li>
              ))}
            </ul>

            <Button href={`tel:${phone}`} text={phone} contained={false} />

            <Button href="/book-now" text="Book Now" />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;

const Burger = ({ onClick }) => (
  <div className="burger" {...{ onClick }}>
    <span />
    <span />
    <span />
  </div>
);

const CloseButton = ({ onClick }) => <div className="close" {...{ onClick }} />;
