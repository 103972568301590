import './movingServices.scss';

const MovingServices = () => (
  <section className="moving-services">
    <div className="container">
      <h2 className="section-title">Moving Services</h2>

      <div className="moving-services__grid">
        <div>
          <img src="../images/services/local-moving.svg" alt="Local Moving" />

          <p>Local Moving</p>
        </div>

        <div>
          <img src="../images/services/residential-moving.svg" alt="Residential Moving" />

          <p>Residential Moving</p>
        </div>

        <div>
          <img src="../images/services/commercial-moving.svg" alt="Commercial Moving" />

          <p>Commercial Moving</p>
        </div>

        <div>
          <img src="../images/services/antique-movers.svg" alt="Antique Movers" />

          <p>Antique Movers</p>
        </div>

        <div>
          <img src="../images/services/heavy-items-moving.svg" alt="Heavy Items Moving" />

          <p>Heavy Items Moving</p>
        </div>

        <div>
          <img src="../images/services/packing-services.svg" alt="Packing Services" />

          <p>Packing Services</p>
        </div>
      </div>
    </div>
  </section>
);

export default MovingServices;
