import { Button } from '../../components';

import './book.scss';

const BookMove = ({ phone }) => (
  <section className="book-your-move">
    <div className="container">
      <h2 className="section-title">
        Book your move
        <br />
        online today!
      </h2>

      <div className="links">
        <Button href="/book-now" text="Book Now" />
        <span>
          or call us <a href={`tel:${phone}`}>{phone}</a>
        </span>
      </div>
    </div>
  </section>
);

export default BookMove;
