import useFetch from 'react-fetch-hook';

import { Layout, Seo } from '../components';

import { Hero, About, Services, GetQuote, WhyWe, Areas, Satisfaction, CallUs, Reviews, ContactUs } from '../sections';

const SETTINGS_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2/general_settings?slug=general-settings';

const ADMIN_URL = 'https://admin.spacemovingsf.com/wp-json/wp/v2';

const Home = () => {
  const settings = useFetch(SETTINGS_URL);
  const { data, error, isLoading } = useFetch(`${ADMIN_URL}/pages?slug=home`);

  if (isLoading || settings.isLoading) return;

  return (
    <Layout>
      <Seo data={data[0]?.yoast_head_json} />
      <Hero title={data[0]?.acf.title} />
      <About title={data[0]?.acf.about.title} content={data[0]?.acf.about.content} />
      <Services title={data[0]?.acf.services.title} list={data[0]?.acf.services.list} />
      <GetQuote />
      <WhyWe
        title={data[0]?.acf.why_space_moving.title}
        content={data[0]?.acf.why_space_moving.content}
        labels={data[0]?.acf.why_space_moving.labels}
      />
      <Areas title={settings.data[0].acf.areas_title} areas={settings.data[0].acf.areas} />
      <Satisfaction />
      <CallUs phone={settings.data[0].acf.contacts.phone} />
      <Reviews data={settings.data[0].acf.reviews} />
      <ContactUs phone={settings.data[0].acf.contacts.phone} />
    </Layout>
  );
};

export default Home;
