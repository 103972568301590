import './whyWeSmall.scss';

const WhyWeSmall = () => (
  <section className="why-we-small">
    <div className="container">
      <h2 className="section-title">Why Space Moving?</h2>

      <div className="why-we-small__grid">
        <div className="why-we-small__card">
          <img src="../images/safe-and-secure.svg" alt="Safe And Secure" />
          <span>Safe And Secure</span>
        </div>

        <div className="why-we-small__card">
          <img src="../images/transparent-delivery-process.svg" alt="Transparent Delivery Process" />
          <span>Transparent Delivery Process</span>
        </div>

        <div className="why-we-small__card">
          <img src="../images/fully-licensed.svg" alt="Fully Licensed" />
          <span>Fully Licensed & Insured</span>
        </div>

        <div className="why-we-small__card">
          <img src="../images/always-on-time.svg" alt="Always On Time" />
          <span>Always On Time</span>
        </div>
      </div>
    </div>
  </section>
);

export default WhyWeSmall;
