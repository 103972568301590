import { Link } from 'react-router-dom';

import './button.scss';

const Button = ({ href, text, contained = true }) => (
  <Link to={href} className={`btn ${contained ? 'contained' : 'outlined'}`}>
    <span>{text}</span>
  </Link>
);

export default Button;
