import { Fragment } from 'react';
import { Button, ApiForm } from '../../components';

import './hero.scss';

const Hero = ({ title }) => {
  return (
    <section className="hero">
      <div className="container">
        <div className="hero__content">
          <h1>
            {title === 'Space Moving' && title}
            {title !== 'Space Moving' && (
              <Fragment>
                <span dangerouslySetInnerHTML={{ __html: title }} />
              </Fragment>
            )}
          </h1>

          {title === 'Space Moving' ? (
            <p>
              Rated #1 Moving Services
              <br />
              in San Francisco Bay Area
            </p>
          ) : (
            <p>in San Francisco Bay Area</p>
          )}

          <Button href="/book-now" text="Book Now" />
        </div>

        <div className="hero__form">
          <ApiForm />

          <p>*By submitting this form, you authorize Space Moving to contact you via email, phone, or text message.</p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
