import { Button } from '../../components';

import './contactUs.scss';

const ContactUs = ({ phone }) => (
  <section className="contact-us">
    <div className="container">
      <h2 className="section-title">
        Contact us for
        <br />
        {` `}
        your FREE quote
      </h2>

      <p>
        Get Expert Moving Services and Enjoy a<br />
        Stress-Free Relocation.
      </p>

      <div className="links">
        <Button href={`tel:${phone}`} text={phone} />
        <span>Call us now!</span>
      </div>

      <div className="payments">
        <img src="../images/mastercard.svg" alt="mastercard" />
        <img src="../images/visa.svg" alt="visa" />
        <img src="../images/discover.svg" alt="discover" />
        <img src="../images/ae.svg" alt="ae" />
      </div>
    </div>
  </section>
);

export default ContactUs;
